import {useState} from 'react';
import {
  Badge,
  Button,
  Divider,
  Heading,
  Hidden,
  HStack,
  IconButton,
  Link,
  Modal,
  Box,
  Stack,
  Text,
  Tooltip,
  VStack,
  Pressable,
} from 'native-base';
import {
  FaArrowRightLong,
  FaCheck,
  FaRegPenToSquare,
  FaBan,
  FaAngleDown,
  FaMapLocationDot,
  FaTrashCan,
  FaCircleExclamation,
  FaAngleUp,
} from 'react-icons/fa6';
import {PiDeviceMobileCameraLight} from 'react-icons/pi';
import {MdPerson} from 'react-icons/md';
import {admin, firestore} from '../firebase.js';
import {useUser} from '../contexts/userContext';
import getDepartmentColor from '../utils/getDepartmentColor';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import {useNavigate} from 'react-router-dom';
import {useOrganization} from '../contexts/OrganizationContext.js';

export default function TripCard({
  trip,
  setRemovedTrips,
  isExpanded,
  setIsExpanded,
  showEmployeeDetails = true,
  openRouteMapModal,
  openEditTripInfoModal,
  openDeleteTripModal,
  openRejectTripModal,
}) {
  const [isDisapproveButtonVisible, setIsDisapproveButtonVisible] =
    useState(false);
  const [user] = useUser();
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState();
  const navigate = useNavigate();
  const [isMouseHovering, setIsMouseHovering] = useState();
  const [organization] = useOrganization();

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClickApprove = () => {
    if (
      (user.isModerator && trip.status === 'approved_by_moderator') ||
      (!user.isModerator && trip.status === 'approved')
    ) {
      setIsDisapproveButtonVisible(true);
      setTimeout(() => {
        setIsDisapproveButtonVisible(false);
      }, 2400);
    } else if (
      (!user.isModerator && trip.status === 'pending_approval') ||
      (user.isModerator &&
        ['pending_approval', 'approved'].includes(trip.status))
    ) {
      const payload = {};
      if (user.isModerator) {
        payload.status = 'approved_by_moderator';
        payload['statusUpdateTime.approvedByModeratorTime'] =
          admin.firestore.FieldValue.serverTimestamp();
      } else {
        payload.status = 'approved';
        payload.isApprovedByDepartment = true;
        payload['statusUpdateTime.approvedTime'] =
          admin.firestore.FieldValue.serverTimestamp();
      }
      setRemovedTrips(prev => [...prev, trip.id]);
      return firestore.collection('trips').doc(trip.id).update(payload);
    }
  };

  const handleClickPaid = () => {
    if (trip.status !== 'paid') {
      setRemovedTrips(prev => [...prev, trip.id]);
      return firestore.collection('trips').doc(trip.id).update({
        status: 'paid',
        'statusUpdateTime.paidTime':
          admin.firestore.FieldValue.serverTimestamp(),
      });
    }
  };

  const disapprove = async () => {
    if (trip.isApprovedByDepartment && user.isModerator) {
      await firestore.collection('trips').doc(trip.id).update({
        status: 'approved',
      });
    } else {
      await firestore.collection('trips').doc(trip.id).update({
        status: 'pending_approval',
      });
    }
    setRemovedTrips(prev => [...prev, trip.id]);
    setIsDisapproveButtonVisible(false);
  };

  const undoReject = async () => {
    if (user.isModerator) {
      await firestore.collection('trips').doc(trip.id).update({
        status: 'approved',
        remarks: admin.firestore.FieldValue.delete(),
      });
    } else {
      await firestore.collection('trips').doc(trip.id).update({
        status: 'pending_approval',
        remarks: admin.firestore.FieldValue.delete(),
      });
    }
    setRemovedTrips(prev => [...prev, trip.id]);
  };

  const openImageModal = i => {
    setIsImageModalOpen(true);
    setSelectedExpense(i);
  };

  const getLocationIssueTooltipContent = () => {
    let content = '';
    if (trip.wasGPSDisabled) {
      content += 'GPS was turned off during the trip\n';
    }
    if (trip.sameRouteCheckCount > 5) {
      content += 'Same route as:\n';
      trip.sameRoute.forEach(employee => {
        content += `- ${employee.employeeName}\n`;
      });
    }
    return content;
  };

  const getTooltipContent = () => {
    let content = ``;
    if (trip.statusUpdateTime?.approvedTime) {
      content += `Approved: ${trip.getApprovedTimeFormatted()}\n`;
    }
    if (trip.statusUpdateTime?.approvedByModeratorTime) {
      content += `Approved by moderator: ${trip.getApprovedByModeratorTimeFormatted()}\n`;
    }
    if (trip.statusUpdateTime?.paidTime) {
      content += `Paid: ${trip.getPaidTimeFormatted()}`;
    }
    return content;
  };

  return (
    <>
      <Box
        onMouseEnter={() => setIsMouseHovering(trip.id)}
        onMouseLeave={() => setIsMouseHovering(null)}
        w="full"
        rounded={['none', 'lg']}
        overflow="hidden"
        borderColor="coolGray.200"
        borderWidth="1"
        position="relative"
        bg={trip.status === 'live' ? 'success.50' : 'white'}
        style={{
          boxShadow: isExpanded
            ? 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px'
            : 'none',
        }}
        p="6">
        <Stack direction={['column', 'row']} space="2">
          <VStack flex="2" space="2">
            {showEmployeeDetails && (
              <Heading size="md">
                <Link
                  isUnderlined={false}
                  onPress={() => navigate(`/employees/${trip.user.id}`)}
                  _hover={{
                    _text: {
                      color: 'primary.400',
                    },
                  }}>
                  {trip.user.name + ' '}
                </Link>
                {trip.deviceInfo && (
                  <Tooltip
                    label={
                      trip.deviceInfo.manufacturer +
                      ' ' +
                      trip.deviceInfo.model +
                      '\nAndroid ' +
                      trip.deviceInfo.apiVersion
                    }
                    hasArrow="true">
                    <Text>
                      <PiDeviceMobileCameraLight fontSize="16" />
                    </Text>
                  </Tooltip>
                )}
              </Heading>
            )}
            <HStack space="2">
              {showEmployeeDetails && (
                <Badge
                  variant="solid"
                  colorScheme={getDepartmentColor(trip.user.department)}
                  rounded="full">
                  {trip.user.department}
                </Badge>
              )}
              <Badge colorScheme="info" rounded="full">
                {trip.vehicleType}
              </Badge>
              {trip.leadInfo && trip.leadInfo.length && (
                <Badge variant="solid" colorScheme="success" rounded="full">
                  Lead generated
                </Badge>
              )}
            </HStack>
            {isExpanded && trip.customers?.length ? (
              <VStack space="2">
                <Divider />
                <Heading size="md">Customers</Heading>
                <VStack space="1">
                  {trip.customers.map((customer, index) => {
                    return (
                      <VStack
                        key={index}
                        overflow="hidden"
                        borderRadius="5"
                        borderColor="muted.100"
                        borderWidth="1">
                        <HStack bg="muted.100" alignItems="center" space="1">
                          <MdPerson size="28" color="gray" />
                          <VStack>
                            <Text>{customer.name}</Text>
                            {customer.serviceType && (
                              <Text fontSize="12" color="muted.500">
                                {
                                  organization.settings.serviceTypes[
                                    customer.serviceType
                                  ]
                                }
                              </Text>
                            )}
                          </VStack>
                        </HStack>
                        {customer.outcome?.length > 0 && (
                          <Text p="2">{customer.outcome}</Text>
                        )}
                      </VStack>
                    );
                  })}
                </VStack>
              </VStack>
            ) : (
              <Text isTruncated maxW="300">
                {trip.customers?.map(c => c.name).join(', ')}
              </Text>
            )}

            {isExpanded && trip.leadInfo && (
              <VStack space="2">
                <Divider />
                <Heading size="md">Lead</Heading>
                <VStack space="1">
                  {trip.leadInfo.map((lead, index) => {
                    return (
                      <VStack
                        key={index}
                        overflow="hidden"
                        borderRadius="5"
                        borderColor="muted.100"
                        borderWidth="1">
                        <HStack bg="muted.100" alignItems="center" space="1">
                          <MdPerson size="28" color="gray" />
                          <VStack>
                            <Text>{lead.name}</Text>
                            <Text fontSize="12" color="muted.500">
                              {lead.contactNumber}
                            </Text>
                          </VStack>
                        </HStack>
                        <Text p="2">{lead.requirements}</Text>
                      </VStack>
                    );
                  })}
                </VStack>
              </VStack>
            )}
          </VStack>

          <Hidden from="sm">
            <Divider />
          </Hidden>

          <VStack flex="1" space="2">
            <Text>{trip.getTripDate()}</Text>
            {isExpanded && trip.vehicleType !== 'Public transport' ? (
              <HStack space="2">
                <Text fontSize="xl">{trip.getStartTimeFormatted()}</Text>
                <VStack alignItems="center" space="-2">
                  <Text>
                    <b>{trip.getTimeElapsedFormatted().hours}</b>h&nbsp;
                    <b>{trip.getTimeElapsedFormatted().minutes}</b>m
                  </Text>
                  <FaArrowRightLong />
                </VStack>
                {trip.endTime ? (
                  <Text fontSize="xl">{trip.getEndTimeFormatted()}</Text>
                ) : (
                  <Text fontSize="xl">--:--</Text>
                )}
              </HStack>
            ) : (
              <Text>
                <b>Duration:</b>&nbsp;
                {trip.vehicleType === 'Public transport' ? (
                  <b>{trip.getServiceDuration()}</b>
                ) : (
                  <>
                    <b>{trip.getTimeElapsedFormatted().hours}</b>h&nbsp;
                    <b>{trip.getTimeElapsedFormatted().minutes}</b>m
                  </>
                )}
              </Text>
            )}
            {trip.vehicleType !== 'Public transport' && (
              <HStack alignItems="end" space="1">
                <Text>
                  <b>Distance:</b>&nbsp;
                  {!isNaN(trip.rectifiedDistance) ? (
                    <>
                      <b>{trip.rectifiedDistance.toFixed(2)}</b>&nbsp;KMs
                    </>
                  ) : (
                    <>
                      <b>{trip.distanceTravelled.toFixed(2)}</b>&nbsp;KMs
                      {trip.claimedDistance && (
                        <Tooltip label="Claimed distance" hasArrow="true">
                          <Text style={{color: 'red'}}>
                            ({`${trip.claimedDistance.toFixed(2)}KMs`})
                          </Text>
                        </Tooltip>
                      )}
                    </>
                  )}
                </Text>
                {getLocationIssueTooltipContent().length > 0 && (
                  <Tooltip
                    label={getLocationIssueTooltipContent()}
                    hasArrow="true">
                    <Text onPress={() => openRouteMapModal(trip)}>
                      <FaCircleExclamation color="red" />
                    </Text>
                  </Tooltip>
                )}
              </HStack>
            )}
            {isExpanded && trip.vehicleType !== 'Public transport' && (
              <Button
                colorScheme="secondary"
                onPress={() => openRouteMapModal(trip)}
                endIcon={<FaMapLocationDot color="#fff" size="16" />}>
                Show Route
              </Button>
            )}
          </VStack>

          <Hidden from="sm">
            <Divider />
          </Hidden>

          <VStack
            flex="2"
            justifyContent="space-between"
            alignItems="flex-end"
            space="2">
            <HStack>
              {isExpanded ? (
                <VStack
                  borderWidth="1"
                  borderColor="muted.300"
                  borderRadius="5"
                  overflow="hidden">
                  <HStack
                    p="2"
                    alignItems="center"
                    space="2"
                    justifyContent="space-between">
                    <Text textAlign="right">T.A.</Text>
                    <HStack>
                      {trip.vehicleType !== 'Public transport' ? (
                        <HStack w="200" space="2" justifyContent="end">
                          <VStack>
                            <Text textAlign="right">
                              {`₹${trip.getTA().toFixed(2)}`}
                            </Text>
                            {trip.travelExpenses?.map((expense, i) => {
                              return (
                                <Link
                                  isUnderlined={false}
                                  _hover={
                                    expense.receiptImage
                                      ? {
                                          _text: {
                                            color: 'primary.400',
                                          },
                                        }
                                      : {cursor: 'text'}
                                  }
                                  onPress={e => {
                                    e.stopPropagation();
                                    if (expense.receiptImage) {
                                      openImageModal(i);
                                    }
                                  }}
                                  key={i}>
                                  {expense.expenseName}
                                  {'   '}
                                  {`₹${
                                    expense.rectifiedAmount?.toFixed(2) ||
                                    expense.amount?.toFixed(2)
                                  }`}
                                </Link>
                              );
                            })}
                          </VStack>
                          {trip.claimedDistance && (
                            <Tooltip label="Claimed allowances" hasArrow="true">
                              <Text textAlign="right" style={{color: 'red'}}>
                                ₹{trip.getTAForClaimedDistance().toFixed(2)}
                              </Text>
                            </Tooltip>
                          )}
                        </HStack>
                      ) : (
                        <VStack maxWidth="300" alignItems="flex-end">
                          {trip.travelExpenses.map((expense, i) => {
                            return (
                              <Link
                                isUnderlined={false}
                                _hover={
                                  expense.receiptImage
                                    ? {
                                        _text: {
                                          color: 'primary.400',
                                        },
                                      }
                                    : {cursor: 'text'}
                                }
                                onPress={e => {
                                  e.stopPropagation();
                                  if (expense.receiptImage) {
                                    openImageModal(i);
                                  }
                                }}
                                key={i}>
                                {expense.expenseName}
                                {'   '}
                                {`₹${
                                  expense.rectifiedAmount?.toFixed(2) ||
                                  expense.amount?.toFixed(2)
                                }`}
                              </Link>
                            );
                          })}
                        </VStack>
                      )}
                    </HStack>
                  </HStack>
                  <Divider />
                  <HStack
                    p="2"
                    alignItems="center"
                    justifyContent="space-between">
                    <Text textAlign="right">D.A.</Text>
                    <HStack>
                      {!isNaN(trip.rectifiedDa) && trip.rectifiedDa !== null ? (
                        <Text w="200" textAlign="right">
                          {`₹${trip.rectifiedDa.toFixed(2)}`}
                        </Text>
                      ) : (
                        <HStack w="200" space="2" justifyContent="end">
                          <Text textAlign="right">
                            {`₹${trip.getDA().toFixed(2)}`}
                          </Text>
                          {trip.claimedDistance &&
                            isNaN(trip.rectifiedDistance) && (
                              <Tooltip
                                label="Claimed allowances"
                                hasArrow="true">
                                <Text textAlign="right" style={{color: 'red'}}>
                                  ₹{trip.getDAForClaimedDistance().toFixed(2)}
                                </Text>
                              </Tooltip>
                            )}
                        </HStack>
                      )}
                    </HStack>
                  </HStack>
                  <Divider />
                  <HStack bg="gray.100" p="2" alignItems="center">
                    <Text fontSize="xl" textAlign="right">
                      Total
                    </Text>
                    <HStack flex="1" space="2" justifyContent="flex-end">
                      <Text textAlign="right" bold fontSize="xl">
                        {`₹${trip.getTotalAllowance().toFixed(2)}`}
                      </Text>
                      {trip.claimedDistance &&
                        isNaN(trip.rectifiedDistance) && (
                          <Tooltip label="Claimed allowances" hasArrow="true">
                            <Text
                              textAlign="right"
                              bold
                              fontSize="xl"
                              style={{color: 'red'}}>
                              ₹
                              {trip
                                .getTotalAllowanceForClaimedDistance()
                                .toFixed(2)}
                            </Text>
                          </Tooltip>
                        )}
                    </HStack>
                  </HStack>
                </VStack>
              ) : (
                <VStack alignItems="end">
                  <Text bold fontSize="xl">
                    {`₹${trip.getTotalAllowance().toFixed(2)}`}
                    &nbsp;
                    {trip.claimedDistance && isNaN(trip.rectifiedDistance) && (
                      <Tooltip
                        label="Claimed allowances"
                        hasArrow="true"
                        isDisabled={!trip.claimedDistance}>
                        <Text bold fontSize="xl" style={{color: 'red'}}>
                          (₹
                          {trip
                            .getTotalAllowanceForClaimedDistance()
                            .toFixed(2)}
                          )
                        </Text>
                      </Tooltip>
                    )}
                  </Text>
                </VStack>
              )}
            </HStack>

            {trip.remarks && (
              <Tooltip maxW="300" hasArrow="true" label={trip.remarks}>
                <Text>Remarks</Text>
              </Tooltip>
            )}

            <HStack space="2">
              {isExpanded && (
                <IconButton
                  size="lg"
                  variant="outline"
                  colorScheme="danger"
                  _hover={{
                    bg: 'danger.100',
                  }}
                  icon={<FaTrashCan color="#f43f5e" size="16" />}
                  onPress={() => openDeleteTripModal(trip)}
                />
              )}
              {(['pending_approval', 'approved'].includes(trip.status) ||
                user.isModerator ||
                user.isFinance ||
                user.isAdmin) &&
                isExpanded && (
                  <HStack space="2">
                    <IconButton
                      size="lg"
                      variant="subtle"
                      colorScheme="secondary"
                      icon={<FaRegPenToSquare color="#93908f" size="16" />}
                      onPress={() => openEditTripInfoModal(trip)}
                    />
                    <Tooltip label="Reject">
                      <IconButton
                        size="lg"
                        variant="solid"
                        colorScheme="warning"
                        icon={<FaBan color="#fff" size="16" />}
                        onPress={() => openRejectTripModal(trip)}
                      />
                    </Tooltip>
                  </HStack>
                )}
              {trip.status === 'live' ? (
                <></>
              ) : isDisapproveButtonVisible ? (
                <Button colorScheme="danger" onPress={disapprove}>
                  <Text color="white" bold>
                    Click again to Disapprove
                  </Text>
                </Button>
              ) : trip.status === 'rejected' ? (
                <Tooltip label="Undo reject" hasArrow>
                  <Button colorScheme="warning" onPress={undoReject}>
                    <Text color="white" bold>
                      Rejected
                    </Text>
                  </Button>
                </Tooltip>
              ) : !user.isFinance ? (
                <Tooltip
                  isDisabled={!trip.statusUpdateTime}
                  label={getTooltipContent()}
                  hasArrow="true">
                  <Button
                    colorScheme={
                      trip.status === 'paid'
                        ? 'yellow'
                        : user.isModerator
                        ? trip.status === 'approved_by_moderator'
                          ? 'success'
                          : 'primary'
                        : ['approved', 'approved_by_moderator'].includes(
                            trip.status,
                          )
                        ? 'success'
                        : 'primary'
                    }
                    onPress={handleClickApprove}
                    isDisabled={trip.status === 'paid'}
                    endIcon={
                      user.isModerator ? (
                        trip.status === 'approved_by_moderator' ? (
                          <FaCheck color="white" size="16" />
                        ) : (
                          <></>
                        )
                      ) : ['approved', 'approved_by_moderator'].includes(
                          trip.status,
                        ) ? (
                        <FaCheck color="white" size="16" />
                      ) : (
                        <></>
                      )
                    }>
                    <Text color="white" bold>
                      {trip.status === 'paid'
                        ? 'Paid'
                        : user.isModerator
                        ? trip.status === 'approved_by_moderator'
                          ? 'Approved'
                          : 'Approve'
                        : ['approved', 'approved_by_moderator'].includes(
                            trip.status,
                          )
                        ? 'Approved'
                        : 'Approve'}
                    </Text>
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip
                  isDisabled={!trip.statusUpdateTime}
                  label={getTooltipContent()}
                  hasArrow="true">
                  <Button
                    colorScheme={trip.status === 'paid' ? 'success' : 'primary'}
                    onPress={handleClickPaid}
                    endIcon={
                      trip.status === 'paid' ? (
                        <FaCheck color="white" size="16" />
                      ) : (
                        <></>
                      )
                    }>
                    <Text color="white" bold>
                      {trip.status === 'paid' ? 'Paid' : 'Mark Paid'}
                    </Text>
                  </Button>
                </Tooltip>
              )}
            </HStack>
          </VStack>
        </Stack>
        <Pressable
          display={isMouseHovering === trip.id ? 'flex' : 'none'}
          onPress={toggleIsExpanded}
          bg="gray.200"
          position="absolute"
          bottom="0"
          left="0"
          w="full"
          alignItems="center">
          {isExpanded ? (
            <HStack alignItems="center" space="1">
              <Text bold>Hide</Text>
              <FaAngleUp />
            </HStack>
          ) : (
            <HStack alignItems="center" space="1">
              <Text bold>Show more</Text>
              <FaAngleDown />
            </HStack>
          )}
        </Pressable>
      </Box>
      <Modal
        size="xl"
        useRNModal={true}
        isOpen={isImageModalOpen}
        onClose={() => setIsImageModalOpen(false)}>
        <Modal.Content>
          <Modal.CloseButton />
          {selectedExpense !== undefined && (
            <>
              <Modal.Header>
                <Text fontSize="lg">
                  {trip.travelExpenses[selectedExpense].expenseName} ₹
                  {trip.travelExpenses[selectedExpense].amount.toFixed(2)}
                </Text>
              </Modal.Header>
              <Modal.Body>
                <Zoom>
                  <img
                    src={`https://firebasestorage.googleapis.com/v0/b/trackea-f2596.appspot.com/o/${encodeURIComponent(
                      trip.travelExpenses[selectedExpense].receiptImage.slice(
                        1,
                      ),
                    )}?alt=media`}
                    alt="Receipt image"
                    width="100%"
                  />
                </Zoom>
              </Modal.Body>
            </>
          )}
          <Modal.Footer>
            <Button
              onPress={() => {
                setIsImageModalOpen(false);
              }}>
              Okay
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
}
